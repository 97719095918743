<template>
  <div class="hello">
    <h1>Welcome to (I don't have a name for this project yet!)</h1>
    <h3>How to use:</h3>
    <ul>
      <li>Click the channel name on the left</li>
      <li>Enter the word or phrase you'd like to find and press enter</li>
      <li>Click the link to the video!</li>
    </ul>
    <p>
      Please note that this service searches youtube videos for the word or
      phrases you're looking for.
    </p>
    <p>I am not the content creator for any those channels.</p>
    <p>
      If the functionality of this site has helped you to find something you've
      been looking for and you want me to keep adding more features and channels
      please support the development of this site
    </p>
    <a href="https://www.buymeacoffee.com/mcmahon"
      ><a href="https://www.buymeacoffee.com/mcmahon" target="_blank"
        ><img
          src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
          alt="Buy Me A Coffee"
          style="height: 60px !important; width: 217px !important" /></a
    ></a>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
a {
  color: #42b983;
}
</style>
