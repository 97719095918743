<template>
  <h1>Search {{ channelName }} youtube videos</h1>
  <div class="yt-search">
    <form @submit.prevent="search">
      <div class="form-group">
        <div class="input-group search-bar">
          <input
            type="text"
            placeholder="Search Term"
            name="q"
            id="q"
            v-model="q"
          />
          <div class="input-group-append">
            <input type="submit" value="Search" />
          </div>
        </div>
      </div>
    </form>

    <div v-if="this.results" id="results">
      <table id="results-table" class="center table table-hover table-striped">
        <thead>
          <tr>
            <td>Video</td>
            <td>Text</td>
          </tr>
        </thead>
        <tbody>
          <tr :key="item.videoId" v-for="item in this.results">
            <td>
              <a :href="item.videoUrl" target="_blank">{{ item.videoTitle }}</a>
            </td>
            <td>{{ item.text }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "Search",
  props: ["channelId", "channelName"],
  data() {
    return {
      q: null,
      results: null,
    };
  },
  methods: {
    search() {
      ApiService.search(this.channelId, this.q)
        .then((response) => {
          this.results = response.data;
          console.log(response.data);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.yt-search {
  text-align: center;
}

#results {
  font-size: 2rem;
  font-weight: bold;
}

table {
  width: 90%;
  margin: auto;
  table-layout: fixed;
}

.search-bar {
  font-size: xxx-large;
  margin-left: 1.5em;
  padding-bottom: 1.5em;
}

#q {
  width: 80%;
  text-align: center;
}
</style>
