import axios from "axios";

const apiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000"
      : "https://damiensays.onrender.com",
});

const CATEGORY = process.env.VUE_APP_CATEGORY;

export default {
  getChannels() {
    return apiClient.get('/api/channel-ids/', {params: { category: CATEGORY }})
  },
  registerUser(userData) {
    return apiClient.post('/dj-rest-auth/registration/', userData);
  },
  login(loginData) {
    return apiClient.post('/dj-rest-auth/login/', loginData)
  },
  search(channelId, q) {
    return apiClient.post('/api/search/', {channel_id: channelId, q: q, category: CATEGORY})
  },
  addToken(token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  removeToken() {
    axios.defaults.headers.common["Authorization"] = null;
  }
};
