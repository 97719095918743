<template>
  <div>
    <form @submit.prevent="login">
      <label for="username">Username</label>
      <input
        type="text"
        name="username"
        id="username"
        v-model="username"
        value
      />
      <span v-if="usernameError">{{ usernameError }} </span>

      <label for="password">Password</label>
      <input
        type="password"
        name="password"
        id="password"
        v-model="password"
        value
      />
      <span v-if="passwordError">{{ passwordError }} </span>
      <button type="submit">Login</button>
    </form>
    <span v-if="nonFieldError">{{ nonFieldError }}</span>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "Login.vue",
  data() {
    return {
      username: "",
      usernameError: null,
      password: "",
      passwordError: null,
      nonFieldError: null,
    };
  },
  methods: {
    login() {
      ApiService.login({ username: this.username, password: this.password })
        .then(({ data }) => {
          this.$store.dispatch("setUserData", data);
          this.$router.push({ name: "Home" });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.non_field_errors) {
            this.nonFieldError = err.response.data.non_field_errors.join(',')
          }
        });
    },
  },
};
</script>

<style scoped></style>
