<template>
  <div>
    <form @submit.prevent="register">
      <label for="username">Username</label>
      <input type="text" name="username" id="username" v-model="username" value />
      <span v-if="usernameError">{{ usernameError }} </span>

      <!-- <label for="email">Email</label>
      <input type="email" name="email" id="email" v-model="email" value />
      <span v-if="emailError">{{ emailError }} </span> -->

      <label for="password1">Password</label>
      <input
        type="password"
        name="password1"
        id="password1"
        v-model="password1"
        value
      />
      <span v-if="password1Error">{{ password1Error }} </span>
      <label for="password2">Repeat Password</label>
      <input
        type="password"
        name="password2"
        id="password2"
        v-model="password2"
        value
      />
      <span v-if="password2Error">{{ password2Error }} </span>
      <button type="submit">Register</button>
    </form>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "Register",
  data() {
    return {
      username: "",
      usernameError: null,
      //email: "",
      //emailError: null,
      password1: "",
      password1Error: null,
      password2: "",
      password2Error: null,
    };
  },
  methods: {
    register() {
      console.log("submitting!");
      ApiService.registerUser({
        username: this.username,
        //email: this.email,
        password1: this.password1,
        password2: this.password2,
      }).then((response) => {
          console.log(response);
          this.$router.push({ name: "Home" });
        })
        .catch((err) => {
          console.log(err.response.data);
          console.log(err);
          const responseData = err.response.data;

          if (responseData.username) {
            this.usernameError = responseData.username.join(",");
          }
          if (responseData.email) {
            this.emailError = responseData.email.join(",");
          }
          if (responseData.password1) {
            this.password1Error = responseData.password1.join(",");
          }
          if (responseData.password2) {
            this.password2Error = responseData.password2.join(",");
          }
        });
    },
  },
};
</script>

<style scoped></style>
