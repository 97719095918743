import { createStore } from "vuex";
import ApiService from "@/services/ApiService";

export default createStore({
  state: {
    user: null
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData.user;
      localStorage.setItem('user', JSON.stringify(userData.user));
      ApiService.addToken(userData.token);
    },
    LOGOUT() {
      localStorage.removeItem('user');
      location.reload();
    }
  },
  actions: {
    setUserData({ commit }, userData) {
      commit('SET_USER_DATA', userData);
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
  },
  modules: {},
  getters: {
    loggedIn(state) {
      return !!state.user;
    }
  }
});
