<template>
  <div v-if="this.channelData" class="topnav">
    <div
      v-if="this.currentChannel"
      class="active pointer"
      @click="this.collapsed = !this.collapsed"
    >
      <h1>
        {{ this.currentChannel.channel_title }}
        <i class="fa fa-bars"></i>
      </h1>
    </div>
    <div
      v-else
      class="active pointer"
      @click="this.collapsed = !this.collapsed"
    >
      <h1>Click here to select a channel</h1>
    </div>
    <div v-if="!this.collapsed" id="channels">
      <div
        v-for="item in this.channelData"
        :key="item.channel_id"
        @click="changeChannel(item)"
        class="pointer channel"
      >
        <h1>{{ item.channel_title }}</h1>
      </div>
    </div>
  </div>
  <div v-else class="active"><h1>The channel list is loading...</h1></div>

  <div v-if="currentChannel">
    <div class="yt-search">
      <form @submit.prevent="search">
        <div class="form-group">
          <div class="input-group search-bar">
            <input
              type="text"
              placeholder="Search Term"
              name="q"
              id="q"
              v-model="q"
            />
          </div>
        </div>
      </form>

      <div v-if="this.results" id="results">
        <table
          id="results-table"
          class="center table table-hover table-striped"
        >
          <thead>
            <tr>
              <td>Video</td>
              <td>Text</td>
            </tr>
          </thead>
          <tbody>
            <tr :key="item.videoId" v-for="item in this.results">
              <td>
                <a :href="item.videoUrl" target="_blank">{{
                  item.videoTitle
                }}</a>
              </td>
              <td>{{ item.text }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="!this.searching">
        <div class="hello">
          <h1>Welcome!</h1>
          <h3>How to use:</h3>
          <ul>
            <li>Select the channel you want to search</li>
            <li>Enter the word or phrase you'd like to find and press enter</li>
            <li>Click the link to the video!</li>
          </ul>
          <p>
            Please note that this service searches youtube videos for the word
            or phrases you're looking for.
          </p>
          <p>I am not the content creator for any those channels.</p>
          <p>
            If the functionality of this site has helped you to find something
            you've been looking for and you want me to keep adding more features
            and channels please support the development of this site
          </p>
          <a href="https://www.buymeacoffee.com/mcmahon"
            ><a href="https://www.buymeacoffee.com/mcmahon" target="_blank"
              ><img
                src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                alt="Buy Me A Coffee"
                style="height: 60px !important; width: 217px !important" /></a
          ></a>
          <p></p>
        </div>
      </div>
      <div v-else>Searching...</div>
    </div>
  </div>
  <div v-else>
    <div class="hello">
      <h1>Welcome to (I don't have a name for this project yet!)</h1>
      <h3>How to use:</h3>
      <ul>
        <li>Select the channel you want to search</li>
        <li>Enter the word or phrase you'd like to find and press enter</li>
        <li>Click the link to the video!</li>
      </ul>
      <p>
        Please note that this service searches youtube videos for the word or
        phrases you're looking for.
      </p>
      <p>I am not the content creator for any those channels.</p>
      <p>
        If the functionality of this site has helped you to find something
        you've been looking for and you want me to keep adding more features and
        channels please support the development of this site
      </p>
      <a href="https://www.buymeacoffee.com/mcmahon"
        ><a href="https://www.buymeacoffee.com/mcmahon" target="_blank"
          ><img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            style="height: 60px !important; width: 217px !important" /></a
      ></a>
      <p></p>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  data() {
    return {
      channelData: null,
      currentChannel: null,
      collapsed: true,
      q: null,
      results: null,
      searching: false,
    };
  },
  mounted() {
    this.getChannels();
  },
  methods: {
    getChannels() {
      console.log("Getting the channel list now");
      ApiService.getChannels()
        .then((response) => {
          console.log(response);
          this.channelData = response.data;
          console.log(this.channelData)
        })
        .catch((err) => console.log(err));
    },
    changeChannel(newChannel) {
      this.currentChannel = newChannel;
      this.collapsed = true;
      this.results = null;
    },
    search() {
      this.searching = true;
      ApiService.search(this.currentChannel.channel_id, this.q)
        .then((response) => {
          this.searching = false;
          this.results = response.data;
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
          this.searching = false;
        });
    },
  },
};
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.yt-search {
  text-align: center;
}

#results {
  font-size: 1rem;
  font-weight: bold;
}

table {
  width: 90%;
  margin: auto;
  table-layout: fixed;
}

.search-bar {
  font-size: xx-large;
  padding-bottom: 1.5em;
}

.pointer {
  cursor: pointer;
}

#q {
  text-align: center;
  width: 100%;
}
/* Style the navigation menu */
.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.topnav #myLinks {
  display: none;
}

/* Style navigation menu links */
.topnav .channel {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  display: block;
}

/* Style the hamburger menu */
.topnav a.icon {
  background-color: #04aa6d;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

/* Add a grey background color on mouse-over */
.topnav .channel:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active link (or home/logo) */
.active {
  background-color: #04aa6d;
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  display: block;
}
</style>
